import React from "react";

import HeadTwo from "../../../HeadTwo/HeadTwo";
import {Button} from "../Button";
import "./styles.scss";

const PartnerSteps = ({data}) => {
  return (
    <div className='p-steps-wrap mt-24 full-bleed'>
      <HeadTwo>
        How does it <b>work</b> ?
      </HeadTwo>
      <div className='p-steps-bg layouted'>
        <div className='p-steps-grid flex flex-row flex-wrap justify-center'>
          {data.map((step, i) => (
            <div className='p-step md:w-2/4 w-full px-12 md:px-24 my-4 text-center'>
              <h2>{i + 1}</h2>
              <h3 className='medium'>{step}</h3>
            </div>
          ))}
        </div>
        <br />
        <div className='flex flex-row justify-center mt-12'>
          <Button className='primary-cta inline-block text-center' />
        </div>
      </div>
    </div>
  );
};

export default PartnerSteps;
