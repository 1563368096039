import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import React from "react";

import HeadTwo from "../../components/HeadTwo/HeadTwo";
import Layout from "../../components/Layout";
import StructuredData, {
  StructuredDataType,
} from "../../components/StructuredData";
import Benefits from "../../components/pages/partners/Benefits";
import PartnerFooter from "../../components/pages/partners/Footer";
import PartnerRoleHero from "../../components/pages/partners/RoleHero";
import PartnerSteps from "../../components/pages/partners/Steps";

const heroData = {
  image: (
    <StaticImage
      src='https://media.kubric.io/api/assetlib/1495a713-9f1a-4772-bf04-2cbce4b07dc9.png'
      alt=''
      placeholder='blurred'
    />
  ),
  mobileImage: (
    <StaticImage
      src='https://media.kubric.io/api/assetlib/d4fa1ca7-0879-4829-9024-3eaf019ad8ca.png'
      alt=''
      placeholder='blurred'
    />
  ),
  content: (
    <div>
      <p>
        Your followers love your content for the eCommerce insights, product
        recommendations, and great benefits. And we love that! Because at Mason,
        we're all about helping sellers connect their brand story to customer
        journeys through no-code dynamic content across online stores.
      </p>
      <p className='orangeB'>
        So, let's work together and help merchants{" "}
        <b>create rich shopping experiences</b> that lead to higher conversions
        & fewer disappointed customers.
      </p>
      <p className='orangeB'>
        The <b>Mason Affiliate Program</b> can help you sell more, earn more,
        and keep your customers growing.
      </p>
    </div>
  ),
};

const benifits = [
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/b1693a63-f296-4ab8-a270-9f8809e85080.png'
        alt='benifit'
        placeholder='blurred'
      />
    ),
    color: "#FF655B",
    title: "Revenue Sharing",
    p1: <p>200% of the subscription value for every new user referred.</p>,
  },
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/9db04ebf-46cc-4265-b9a7-7d82a96f9b1d.png'
        alt='benifit'
        placeholder='blurred'
      />
    ),
    color: "#FF655B",
    title: "Priority Support",
    p1: <p>Dedicated Account Manager to support you and your clients.</p>,
  },
  {
    icon: (
      <StaticImage
        src='https://media.kubric.io/api/assetlib/b686f409-0c54-4230-ae88-6d9f12495906.png'
        alt='benifit'
        placeholder='blurred'
      />
    ),
    color: "#FF655B",
    title: "Transparent Tracking",
    p1: <p>Unique codes to manage all of your referrals and revenue.</p>,
  },
];

const steps = [
  <span>
    Fill out the partner application form. <br />
    (It take ~30 seconds!)
  </span>,
  "Get your preferred referral code within 24 hours.",
  "Share the referral code through your content.",
  "Receive up to $300 on every new referral.",
];

const affiliate = ({data}) => {
  const seoData = data.allSanitySeoMeta.nodes[0];
  return (
    <Layout seoData={seoData}>
      <StructuredData
        type={StructuredDataType.BREADCRUMB}
        data={{
          name: "Affiliate Partners | Mason",
          items: [
            {
              name: "Affiliate Partners | Mason",
              item: "https://getmason.io/partners/affiliate",
            },
          ],
        }}
      />
      <PartnerRoleHero {...heroData} />
      <div className='mt-24'>
        <HeadTwo>
          Affiliate Partner <b>benefits</b>
        </HeadTwo>
      </div>
      <Benefits data={benifits} />
      <PartnerSteps data={steps} />
      <PartnerFooter color='#FF655B' />
    </Layout>
  );
};

export const query = graphql`
  query {
    allSanitySeoMeta(filter: {page: {eq: "affiliate"}}) {
      nodes {
        title
        canUrl
        tags {
          content
          type {
            attributeType
            attributeValue
          }
        }
      }
    }
  }
`;

export default affiliate;
