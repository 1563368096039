import React from "react";

import {Button} from "../Button";
import "./styles.scss";

const PartnerRoleHero = ({image, mobileImage, content}) => {
  return (
    <div className='partner-role-hero-wrap full-bleed layouted mt-24'>
      <div className='p-role-display mx-auto w-full md:w-2/4 sm:hidden md:block'>
        {image}
      </div>
      <div className='p-role-display mx-auto w-full md:w-2/4 md:hidden sm:block'>
        {mobileImage}
      </div>
      <div className='text-center mt-12 mx-auto px-4 p-role-content w-full md:w-3/4'>
        {content}
      </div>
      <Button className='primary-cta mx-auto inline-block mt-12' />
    </div>
  );
};

export default PartnerRoleHero;
