import React from "react";

import {Button} from "../Button";
import "./styles.scss";

const Benefits = ({data}) => {
  return (
    <div className='p-benifits-wrap'>
      <br />
      <div className='b-cards-wrap w-full px-2 flex flex-col md:flex-row items-stretch'>
        {data.map((benifit) => (
          <div
            style={{borderColor: benifit.color}}
            className='b-card w-full md:w-1/3 px-2 text-center my-2 md:my-0 md:mx-2 box-border p-4'
          >
            <div
              style={{border: `2px solid ${benifit.color}`}}
              className='b-icon mx-auto flex items-center justify-center w-24 h-16'
            >
              {benifit.icon}
            </div>
            <br />
            <h3 style={{color: benifit.color}}>{benifit.title}</h3>
            <br />
            <div className='px-4'>
              {benifit.p1}
              <br />
              {benifit.p2}
            </div>
          </div>
        ))}
      </div>
      {data[0].p2 ? (
        <div className='flex flex-row justify-center'>
          <Button />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Benefits;
